<template>
  <div class="lhUI">
    <div class="head">
      <div class="left">
        <div class="logo">
          <span class="iconfont lh-icon-logo"></span>
        </div>
        <div class="title">
          lhUI
        </div>
      </div>
      <div class="middle">
        <lh-input v-model="searchInput" placeholder="搜索目录" :fetchTips="search" :clearable="true">
          <template slot-scope="scope">
            <lh-router-link width="100%" @click="searchInput=scope.item.tag" :to="scope.item.path">
              <div>{{ scope.item.tag }}</div>
            </lh-router-link>
          </template>
        </lh-input>
      </div>
      <div class="right">
        <lh-select>
          <lh-option>1.0.2</lh-option>
        </lh-select>
      </div>
    </div>
    <div class="content">
      <div class="nav">
        <lh-nav-menu>
          <lh-menu-group icon="icon-log iconfont">
            <div slot="groupName">更新日志</div>
            <template v-for="(item,index) of logRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
          <lh-menu-group icon="icon-guide iconfont">
            <div slot="groupName">开发指南</div>
            <template v-for="(item,index) of guideRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
          <lh-menu-group icon="icon-component iconfont">
            <div slot="groupName">组件</div>
            <template v-for="(item,index) of lhComponentsRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
          <lh-menu-group icon="icon-feedback iconfont">
            <div slot="groupName">意见反馈</div>
            <template v-for="(item,index) of feedbackRoutes">
              <lh-menu-item :to="item.path" :key="index">{{item.meta.tag}}</lh-menu-item>
            </template>
          </lh-menu-group>
        </lh-nav-menu>
      </div>
      <div class="view">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import logRoutes from "./routes/logRoutes"
import guideRoutes from "./routes/guideRoutes"
import lhComponentsRoutes from "./routes/lhComponentsRoutes"
import feedbackRoutes from "./routes/feedbackRoutes"
import routes from "./routes/index"
export default {
    name:"LhUI",
    data(){
      return {
        logRoutes:logRoutes,
        guideRoutes:guideRoutes,
        lhComponentsRoutes:lhComponentsRoutes,
        feedbackRoutes:feedbackRoutes,
        searchInput:""
      }
    },
    methods:{
      search:function(str,callback){
        if(str==""){
          callback(null);
          return;
        }
        let data=[];
        for(let item of routes){
          let tag=item.meta.tag.toLowerCase();
          let lowerStr=str.toLowerCase();
          let index=tag.indexOf(lowerStr);
          if(index>=0){
            data.push({path:item.path,tag:item.meta.tag});
          }
        }
        callback(data);
      }
    }
}
</script>

<style src="../../assets/iconfont.css"></style>
<style lang="less" scoped>
.lhUI{
  height: 100vh;
  //border: solid;
  .head{
    width:100%;
    height: 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    .left{
      display: flex;
      align-items: flex-end;
      .logo{
        padding: 6px;
        .lh-icon-logo{
          font-size: 70px;
        }
      }
      .title{
        padding: 10px;
        font-size: 35px;
        font-weight: 700;
        font-family:'Segoe UI';
      }
    }
  }
  .content{
    //border: solid;
    display: flex;
    justify-content: space-around;
    height: 85%;
    .nav{
      height: 100%;
      //border: solid;
      overflow: auto;
      padding: 10px;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
      margin:5px;
    }
    .view{
      height: 100%;
      overflow: auto;
    }
  }
}
</style>